<template>
  <div class="web_pla_int">
    <!-- 澳新购 -->
    <div class="item">
      <div>
        <img class="bg-image" src="@/assets/img/az/Vector1@2x.png" alt="" />
      </div>
      <!-- 内容 -->
      <div class="">
        <div class="top_det clw100">
          <div class="wapfont18  wel_top">
            {{ $t("AaAbordTop.introductionBug.title") }}
          </div>
          <div class=" wapfont14 text-des">
            <div class="text-item">
              <div class="pointer"></div>
              <div>{{ $t("AaAbordTop.introductionBug.first") }}</div>
            </div>
            <div class="text-item">
              <div class="pointer"></div>
              <div>{{ $t("AaAbordTop.introductionBug.second") }}</div>
            </div>
            <div class="text-item">
              <div class="pointer"></div>
              <div>{{ $t("AaAbordTop.introductionBug.third") }}</div>
            </div>
            <!-- {{ $t("AaAbordTop.introductionService.text") }} -->
          </div>
        </div>
        <div class="bot_btn">
          <div
            class="wapfont14 det_btn clf100"
            :class="$t('languages') == 'EN' ? 'det_btn_en' : ''"
            @click="goApply(1)"
          >
            <span>{{ $t("AaAbordTop.showMore") }}</span>
            <img src="@/assets/img/az/go.png" alt="" />
          </div>
        </div>
      </div>
      <!-- 二维码 -->
      <div class="wap_eq">
        <div class="eq_item">
          <!-- 苹果二维码 -->
          <img
            :src="$t('AaAbordTop.introductionBug.apple_aoxingou_code')"
            alt=""
            class="eq_img"
          />
          <!-- 苹果按钮 -->
          <a :href="apple_aoxingou_url">
            <img
              :src="$t('AaAbordTop.onlinedownload.iosLogo')"
              alt=""
              class="down_logo"
            />
          </a>
        </div>
        <div class="eq_item">
          <!-- 安卓二维码 -->
          <img
            :src="$t('AaAbordTop.introductionBug.android_aoxingou_code')"
            alt=""
            class="eq_img"
          />
          <!-- 安卓按钮 -->
          <img
            v-if="isWxClient && isMobileTerminal"
            :src="$t('AaAbordTop.onlinedownload.androidLogo')"
            alt=""
            class="down_logo"
            @click="showGuid"
          />
          <a v-else :href="android_aoxingou_url">
            <img
              :src="$t('AaAbordTop.onlinedownload.androidLogo')"
              alt=""
              class="down_logo"
            />
          </a>
        </div>
      </div>
      <!-- 底部大图 -->
      <img
        :src="$t('AaAbordTop.introductionBug.right_logo')"
        alt=""
        class="phone_logo"
      />
    </div>
    <!-- 澳新通 -->
    <div class="item clf100 block-color">
      <div>
        <img class="bg-image" src="@/assets/img/az/Vector1@2x.png" alt="" />
      </div>
      <!-- 内容 -->
      <div class="top_det">
        <div class="wapfont18  wel_top">
          {{ $t("AaAbordTop.introductionService.title") }}
        </div>
        <div class=" wapfont14 text-des">
          <div class="text-item">
            <div class="pointer"></div>
            <div>{{ $t("AaAbordTop.introductionService.first") }}</div>
          </div>
          <div class="text-item">
            <div class="pointer"></div>
            <div>{{ $t("AaAbordTop.introductionService.second") }}</div>
          </div>
          <div class="text-item">
            <div class="pointer"></div>
            <div>{{ $t("AaAbordTop.introductionService.third") }}</div>
          </div>
          <!-- {{ $t("AaAbordTop.introductionService.text") }} -->
        </div>
      </div>
      <div class="bot_btn">
        <div
          class="wapfont14 det_btn clf100"
          :class="$t('languages') == 'EN' ? 'det_btn_en' : ''"
          @click="goApply(2)"
        >
          <span>{{ $t("AaAbordTop.showMore") }}</span>
          <img src="@/assets/img/az/go.png" alt="" />
        </div>
      </div>
      <!-- 二维码 -->
      <div class="wap_eq">
        <div class="eq_item">
          <!-- 苹果二维码 -->
          <img
            :src="$t('AaAbordTop.introductionService.apple_aoxintong_code')"
            alt=""
            class="eq_img"
          />
          <!-- 苹果按钮 -->
          <a :href="apple_aoxintong_url">
            <img
              :src="$t('AaAbordTop.onlinedownload.iosLogo')"
              alt=""
              class="down_logo"
            />
          </a>
        </div>
        <div class="eq_item">
          <!-- 安卓二维码 -->
          <img
            :src="$t('AaAbordTop.introductionService.android_aoxintong_code')"
            alt=""
            class="eq_img"
          />
          <!-- 安卓按钮 -->
          <img
            v-if="isWxClient && isMobileTerminal"
            :src="$t('AaAbordTop.onlinedownload.androidLogo')"
            alt=""
            class="down_logo"
            @click="showGuid"
          />
          <a v-else :href="android_aoxintong_url">
            <img
              :src="$t('AaAbordTop.onlinedownload.androidLogo')"
              alt=""
              class="down_logo"
            />
          </a>
        </div>
      </div>
      <!-- 底部大图 -->
      <img
        :src="$t('AaAbordTop.introductionService.right_logo')"
        alt=""
        class="phone_logo"
      />
    </div>
    <!-- 引导弹窗 -->
    <div v-if="show" class="guid_back" @click.stop="closeGuid">
      <div class="guid">
        <img src="@/assets/download/guid.png" alt="" />
      </div>
      <div class="mask_c">
        <div class="hint1">1. 点击右上角</div>
        <div class="hint2">
          {{
            !isIPhoneOrIPad
              ? "2. 点击`在浏览器中打开`"
              : "2. 点击`在Safari中打开`"
          }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  isIPhoneOrIPad,
  isWxClient,
  isMobileTerminal,
  isAndroid
} from "@/utils/pattern";
export default {
  data() {
    return {
      show: false,
      apple_aoxingou_url:
        "https://apps.apple.com/cn/app/az%E6%BE%B3%E6%96%B0%E8%B4%AD/id1590792955",
      apple_aoxintong_url:
        "https://apps.apple.com/cn/app/az%E6%BE%B3%E6%96%B0%E9%80%9A/id1590793270",
      android_aoxingou_url:
        "https://assets-apk.myazstore.com/app/axg-android.apk",
      android_aoxintong_url:
        "https://assets-apk.myazstore.com/app/axt-android.apk"
    };
  },
  computed: {
    isIPhoneOrIPad() {
      return isIPhoneOrIPad();
    },
    isWxClient() {
      return isWxClient();
    },
    isMobileTerminal() {
      return isMobileTerminal();
    },
    isAndroid() {
      return isAndroid();
    }
  },
  methods: {
    goApply(index) {
      let url = "https://myaz.com.au";
      if (index === 1) {
        url = "https://myaz.com.au";
      } else {
        url = "https://azgwholesale.com.au/";
      }
      window.open(url);
    },
    showGuid() {
      if (!this.isWxClient) {
        return;
      }
      this.show = true;
    },
    closeGuid() {
      this.show = false;
    }
  }
};
</script>

<style lang="less" scoped>
.bg-image {
  width: calc(100vw * 19 / 37.5);
  // margin-left: calc(100vw * 4 / 37.5);
  position: absolute;
  right: 0;
  top: calc(-100vw * 2.4 / 37.5);
  // z-index: -1;
}
.web_pla_int {
  margin-top: calc(100vw * 2.4 / 37.5);
  text-align: left;
  .item {
    font-family: PingFang SC, PingFang SC-Regular;
    overflow: hidden;
    position: relative;
    width: calc(100vw * 35.1 / 37.5);
    // height: calc(100vw * 40.3 / 192);
    background: #fff;
    border: 1px solid #fff;
    box-shadow: 0px calc(100vw * 0.4 / 37.5) calc(100vw * 1.6 / 37.5) 0px
      rgba(0, 0, 0, 0.05);

    border-radius: calc(100vw * 1.2 / 37.5);
    margin: 0 auto;
    margin-bottom: calc(100vw * 2.4 / 37.5);
    .text-des {
      color: rgba(0, 0, 0, 0.6);
      line-height: calc(100vw * 2.6 / 37.5);
    }

    &.block-color {
      color: rgba(2, 76, 130, 0.8);
    }
  }
}
.item {
  padding: calc(100vw * 2.4 / 37.5);
  .text-item {
    display: flex;
    text-align: justify;
    .pointer {
      display: none;

      line-height: 0;
      &::before {
        content: "";
        display: inline-block;
        width: 4px;
        height: 4px;
        border-radius: 2px;
        background: rgba(0, 0, 0, 0.5);
      }
      margin-top: calc(100vw * 0.9 / 37.5);
      margin-right: calc(100vw * 1.4 / 37.5);
    }
  }
  box-sizing: border-box;
  // .top_det{
  //     // position: absolute;
  //     margin-left: calc(100vw * 7.2 / 192);
  //     margin-right: calc(100vw * 7.2 / 192);
  //     margin-top: calc(100vw * 3.6 / 192);
  //     font-size: calc(100vw * 2.8 / 192);
  //     z-index: 99;
  // }
  .wel_top {
    margin-bottom: calc(100vw * 2.4 / 37.5);
  }
  .bot_btn {
    // position: absolute;
    // left: calc(100vw * 7.2 / 192);
    // bottom: calc(100vw * 3.6 / 192);
    // display: flex;
    margin-top: calc(100vw * 2.4 / 37.5);
  }
  .det_btn {
    z-index: 99;
    height: calc(100vw * 3.6 / 37.5);
    width: calc(100vw * 9 / 37.5);
    &.det_btn_en {
      width: calc(100vw * 10.6 / 37.5);
    }
    // line-height:  calc(100vw * 3.6 / 37.5);
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.9);
    padding: 0 calc(100vw * 3.4 / 37.5);
    padding-right: calc(100vw * 1.2 / 37.5);
    background: #fffaf0;
    border: 1px solid rgba(191, 146, 100, 0.5);
    border-radius: calc(100vw * 5.4 / 37.5);
    margin-top: calc(100vw * 1.1 / 37.5);
    cursor: pointer;
    // font-size: calc(100vw * 1.2 / 37.5);
    font-family: PingFang SC, PingFang SC-Regular;
    > * {
      display: inline-block;
      vertical-align: middle;
    }
    img {
      width: calc(100vw * 2 / 37.5);
      margin-left: calc(100vw * 0.4 / 37.5);
    }
  }
}
// 引导弹窗
.guid_back {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 99999;
}
.guid {
  text-align: right;
  padding: 8px 16px;
}
.guid img {
  width: 120px;
  height: 156px;
}
.mask_c {
  background: #fff;
  margin: 8px 24px auto 24px;
  border-radius: 5px;
  padding: 12px;
  font-size: 14px;
  color: #000;
  text-align: left;
}
// 二维码相关
.wap_eq {
  display: flex;
  justify-content: space-between;
  margin-top: calc(100vw * 2.7 / 37.5);
  .eq_img {
    width: calc(100vw * 9 / 37.5);
    display: block;
    margin: 0 auto;
  }
  .down_logo {
    margin-top: calc(100vw * 1.2 / 37.5);
    width: calc(100vw * 12 / 37.5);
  }
  .eq_item {
    width: calc(100vw * 12 / 37.5);
  }
}
.phone_logo {
  display: block;
  margin: 0 auto;
  margin-top: calc(100vw * 4.4 / 37.5);
  width: calc(100vw * 12 / 37.5);
}
</style>
